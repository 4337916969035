import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { AuthContext } from "../../../../context/AuthContext";
import { getInitialsFormUserAttr } from "../../../../helpers/getInitials";
import { useHistory } from "react-router-dom";
import { Initial } from "../../..";
import "./style.css";

export default function Index() {
  const { userAttr, signOut, isAuthenticating, userInfo, tokens } =
    useContext(AuthContext);
  const history = useHistory();
  const [isAgent, setIsAgent] = useState(false);
  useEffect(() => {
    setIsAgent(userInfo?.is_agent);
  }, [userInfo]);
  const agentOptions = [
    {
      label: "Agent Dashboard",
      to: "/agent/dashboard",
    },
  ];
  const applyOptions = [
    {
      label: "Apply as agent",
      to: "/Apply",
    },
  ];
  // console.log("ID TOkens", tokens)
  const commonOptions = [
    // {
    //   label: "Dashboard",
    //   to: "/account/dashboard",
    // },
    {
      label: "Favorites",
      to: "/my-favorites",
    },

    {
      label: "My Listing",
      to: "/account/my-listing",
    },

    {
      label: "List Property",
      to: "/post-your-property",
    },
    // {
    //   label: "Subscription Plans",
    //   to: "/subscriptionPlans",
    // },
  ];

  return (
    <>
      {!isAuthenticating && (
        <Dropdown>
          <Dropdown.Toggle
            style={{
              outline: "none",
              border: "none",
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
            // split
            // id="dropdown-basic"
            className="rounded-circle border-none "
          >
            <Initial />
          </Dropdown.Toggle>

          <Dropdown.Menu
            style={{
              padding: "0px",
              marginTop: "0.3rem",
              borderRadius: "16px",
              // overflow: "hidden",
            }}
            className="profile-dropdown-container"
          >
            <div className="profile-dropdown-menu-container">
              <div
                className="profile-dropdown-menu profile-menu"
                // onClick={() => history.push("/my-profile")}
              >
                <div className="d-flex flex-row align-items-center border-bottom mb-2 ">
                  <div className="m-2">
                    <div
                      style={{ width: "40px", height: "40px" }}
                      className=" bg-danger  rounded-circle d-flex justify-content-center align-items-center"
                    >
                      <span className="text-light p-2">
                        {getInitialsFormUserAttr(userAttr)}
                      </span>
                    </div>
                  </div>

                  <div className="m-2">
                    <div
                      onClick={() => history.push("/account/dashboard")}
                      className="fs-5"
                      style={{
                        width: "200px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {userAttr?.["custom:first_name"] +
                        " " +
                        userAttr?.["custom:last_name"]}
                    </div>
                    {/* <div
                      style={{ fontSize: "12px" }}
                      className="profile-dropdown-menu highlight-hover"
                      onClick={() => {
                        history.push("/account/dashboard");
                      }}
                    >
                      View Profile
                    </div> */}
                  </div>
                </div>
              </div>

              {isAgent
                ? agentOptions?.map((opt) => (
                    <div
                      className="profile-dropdown-menu highlight-hover "
                      onClick={() => history.push(opt?.to)}
                    >
                      {opt?.label}
                    </div>
                  ))
                : applyOptions?.map((opt) => (
                    <div
                      className="profile-dropdown-menu highlight-hover "
                      onClick={() => history.push(opt?.to)}
                    >
                      {opt?.label}
                    </div>
                  ))}
              {commonOptions?.map((opt) => (
                <div
                  className="profile-dropdown-menu highlight-hover"
                  onClick={() => history.push(opt?.to)}
                >
                  {opt?.label}
                </div>
              ))}
            </div>

            <hr className="mx-3 my-2" />

            <div className="profile-dropdown-menu-container">
              <div
                className="profile-dropdown-menu sign-out  d-flex justify-content-center"
                onClick={signOut}
              >
                Sign out
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
